import { useCallback, useEffect, useState } from 'react';

export interface SavedStateParser<T> {
  parse(state: string): T;
  stringify(state: T): string;
}

export class JSONStateParser<T> {
  parse(state: string) {
    return JSON.parse(state);
  }
  stringify(state: T) {
    return JSON.stringify(state);
  }
}

/**
 * @deprecated Use the hook from `@packfleet/hooks` instead.
 */
export function useSavedState<T>(
  initial: T,
  key: string,
  parser: SavedStateParser<T> = new JSONStateParser<T>(),
): [T, (val: T) => void] {
  const [state, _setState] = useState<T>(initial);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: initial, parser.parse
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: localStorage
  useEffect(() => {
    if (typeof localStorage === 'undefined') {
      return;
    }
    const saved = localStorage.getItem(key);
    if (saved == null) {
      _setState(initial); // likely a noop, unless key has changed
      return;
    }
    _setState(parser.parse(saved));
  }, [localStorage, key]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: parser.stringify
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: localStorage
  const setState = useCallback(
    (st: T) => {
      _setState(st);
      if (typeof localStorage === 'undefined') {
        return;
      }
      localStorage.setItem(key, parser.stringify(st));
    },
    [localStorage, key],
  );

  return [state, setState];
}
