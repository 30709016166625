import { Button } from '@/components/ui/button';
import cs from 'classnames';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from '../ui/dialog';

export type Props = {
  show: boolean;
  title: string;
  body?: React.ReactNode;
  confirmText: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  align?: 'center' | 'left';
  loading?: boolean;
};

export const Alert: FunctionComponent<Props> = ({
  show,
  title,
  body,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  align = 'left',
  loading,
}) => {
  const mainRef = useRef<HTMLElement | undefined>(undefined);
  useEffect(() => {
    mainRef.current = document.getElementsByTagName('main')[0];
  });

  return (
    <Dialog open={show} onOpenChange={onCancel ? onCancel : onConfirm}>
      <DialogContent
        className={cs({
          'text-center': align === 'center',
        })}
      >
        <DialogTitle>{title}</DialogTitle>
        {body ? <DialogDescription>{body}</DialogDescription> : null}
        <DialogFooter
          className={cs('mt-4', {
            'justify-end': align === 'left',
            'justify-center': align === 'center',
          })}
        >
          {cancelText ? (
            <Button onClick={onCancel} variant="outline" className="mr-2">
              {cancelText}
            </Button>
          ) : null}
          <Button onClick={onConfirm} disabled={loading} variant="default">
            {loading ? (
              <span className="flex items-center gap-2">
                {/* TODO: Loading */}
                <svg
                  className="mr-2 h-4 w-4 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {confirmText}
              </span>
            ) : (
              confirmText
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Alert;
