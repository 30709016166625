import { IncomingMessage } from 'http';
import {
  ApolloClient,
  QueryOptions,
  ServerError,
  TypedDocumentNode,
  isApolloError,
} from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import * as cookie from 'cookie';
import jwtDecode from 'jwt-decode';
import { AppProps } from 'next/app';
import { addApolloState, initializeApollo } from '../../graphql/client';
import { captureApolloError } from '../../graphql/error';

export function getSSRApolloClient(req?: IncomingMessage) {
  // Parse the session cookie so we can enrich any sentry errors with the logged in user.
  // NOTE: This does _not_ validate the JWT, only parses it. This is fine as we only need it for error metadata.
  // Never rely on this parsed user to perform actions as it's trivial to spoof.
  const result = cookie.parse(req?.headers.cookie ?? '');
  const user: Record<string, string> | undefined = result.session
    ? jwtDecode(result.session)
    : undefined;

  if (user) {
    Sentry.setUser({
      id: user.user_id,
      email: user.email,
    });
  }

  return initializeApollo(
    (process.env.NEXT_PUBLIC_API_URL ?? '') + '/graphql',
    {},
    {
      cookie: req?.headers?.cookie,
    },
  );
}

export function fetchGraphQL<T, V>(
  query: TypedDocumentNode<T, V>,
  variables: V,
  errorPolicy?: QueryOptions['errorPolicy'],
) {
  return async (client: ApolloClient<T>) =>
    await client.query<T, V>({
      query: query,
      variables: variables,
      errorPolicy: errorPolicy ?? 'none',
    });
}

export async function ssrGraphQLRequest(
  client: ApolloClient<any>,
  funcs: ((client: ApolloClient<any>) => any)[],
): Promise<AppProps['pageProps']> {
  try {
    const props: AppProps['pageProps'] = {};
    await Promise.all(funcs.map((f) => f(client)));
    addApolloState(client, { props });
    return props;
  } catch (e) {
    captureApolloError(e.graphQLErrors, e.networkError);
    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);
    if (isApolloError(e)) {
      if (e.networkError) {
        console.error(e.networkError);
        const serverError = e.networkError as ServerError;
        if (serverError.result) {
          console.error(serverError.result);
        }
        throw e;
      }

      const errs = e.graphQLErrors?.filter((err) => {
        return (
          err.extensions?.code !== 'FORBIDDEN' &&
          err.extensions?.code !== 'UNAUTHENTICATED' &&
          err.message !== 'User has no organization'
        );
      });
      if (errs.length === 0 && e.graphQLErrors?.length > 0) {
        return { props: {} };
      } else if (errs.length > 0) {
        errs.forEach((e) => {
          console.error(e);
          console.error(JSON.stringify(e.extensions));
        });
        throw e;
      }
    } else {
      throw e;
    }
  }
}

const _defaultError500 = {
  error: 'Something went wrong',
  statusCode: 500,
};
