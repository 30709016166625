import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import slugify from 'slugify';

import {
  CollectionLocationFragment,
  useListCollectionLocationsQuery,
} from '@/generated/graphql';

import { Routes, route } from '@/utilities/routes';

export const useCollectionLocations = () => {
  const router = useRouter();

  const { locationId } = router.query;

  const [selectedLocation, setSelectedLocation] =
    useState<CollectionLocationFragment | null>(null);

  const onSelectLocation = (
    selectedLocation: CollectionLocationFragment | null,
  ) => {
    if (selectedLocation != null) {
      setSelectedLocation(selectedLocation);

      const slug = slugify(
        selectedLocation.name ??
          `${selectedLocation.address.line1}-${selectedLocation.address.postCode}`,
        { lower: true },
      );

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(
        route(router.pathname as Routes, {
          locationId: selectedLocation.id,
          slug: slug,
        }),
        undefined,
        { shallow: true },
      );
    } else {
      setSelectedLocation(null);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(route(router.pathname as Routes), undefined, {
        shallow: true,
      });
    }
  };

  const { data } = useListCollectionLocationsQuery({
    variables: {
      input: {},
    },
  });

  const collectionLocations =
    data?.listCollectionLocations?.collectionLocations ?? [];

  const hasMultipleLocations =
    collectionLocations && collectionLocations.length > 1;

  useEffect(() => {
    setSelectedLocation(
      collectionLocations.find((l) => locationId === l.id) ?? null,
    );
  }, [collectionLocations, locationId]);

  return {
    selectedLocation,
    onSelectLocation,
    hasMultipleLocations,
    collectionLocations,
  };
};
