import { Combobox } from '@/components/ui/combo-box';
import { CollectionLocationFragment } from '@/generated/graphql';
import { formatCollectionLocation } from '@/utilities/collection-locations';
import { FunctionComponent } from 'react';

export type Props = {
  className?: string;
  locations: CollectionLocationFragment[];
  selectedLocation: CollectionLocationFragment | null;
  onChange: (location: CollectionLocationFragment | null) => void;
};

const selectAllOption = { label: 'Select All', value: '' };
type Option = { label: string; value: string };

const locationToOption = (location: CollectionLocationFragment) => ({
  label: formatCollectionLocation(location),
  value: location.id,
});

export const CollectionLocationsSelector: FunctionComponent<Props> = ({
  locations,
  selectedLocation,
  onChange,
}) => {
  const locationOptions = locations.map((l) => locationToOption(l));
  const options: Option[] = [selectAllOption, ...locationOptions];

  const relayOnChange = (value: string) => {
    const newLocation = locations.find((l) => l.id === value);
    if (newLocation != null) {
      onChange(newLocation);
    } else {
      onChange(null);
    }
  };

  // If there is one location selected then use this as the selected location
  // otherwise assume all locations are selected
  const selectedOption =
    selectedLocation != null
      ? locationToOption(selectedLocation)
      : selectAllOption;

  return (
    <Combobox
      options={options}
      value={selectedOption.value}
      placeholder="All locations"
      className="w-[240px]"
      onChange={(value: string) => relayOnChange(value)}
    />
  );
};
