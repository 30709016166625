import { PackStatus } from '@/generated/graphql';

export enum ShipmentGrouping {
  failed = 'Failed',
  toCollect = 'To collect',
  onTheirWay = 'On their way',
  delivered = 'Delivered',
}

export const SHIPMENT_GROUPINGS: Record<ShipmentGrouping, PackStatus[]> = {
  [ShipmentGrouping.failed]: [PackStatus.FailedDelivery],
  [ShipmentGrouping.toCollect]: [
    PackStatus.Scheduled,
    PackStatus.OutForCollection,
  ],
  [ShipmentGrouping.onTheirWay]: [
    PackStatus.Collected,
    PackStatus.InDepot,
    PackStatus.ExternalCarrierCollected,
    PackStatus.ExternalCarrierInTransit,
    PackStatus.OutForDelivery,
    PackStatus.AvailableForRecipientPickup,
    PackStatus.ToReturnToSender,
    PackStatus.OnHold,
    PackStatus.ExternalCarrierOnHold,
  ],
  [ShipmentGrouping.delivered]: [
    PackStatus.Delivered,
    PackStatus.ReturnedToSender,
  ],
};
