export function formatCollectionLocation(cl: {
  name?: string | null;
  address: {
    line1: string;
    postCode: string;
  };
}) {
  if (!cl.name) {
    return `${cl.address.line1} (${cl.address.postCode})`;
  }
  return `${cl.name} (${cl.address.postCode})`;
}
