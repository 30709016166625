export function groupBy<T extends { [key: string]: any }>(
  array: T[],
  key: keyof T | ((item: T) => string),
): { [key: string]: T[] } {
  const result: { [key: string]: T[] } = {};
  array.forEach((item) => {
    if (typeof key === 'function') {
      (result[key(item)] = result[key(item)] || []).push(item);
    } else {
      (result[item[key]] = result[item[key]] || []).push(item);
    }
  });
  return result;
}

export function capitalize(str: string) {
  const lowered = str.toLowerCase();
  return lowered.charAt(0).toUpperCase() + lowered.slice(1);
}

export function keyBy<T extends { [key: string]: any }>(
  array: T[],
  key: keyof T,
): { [key: string]: T } {
  const result: { [key: string]: T } = {};
  array.forEach((item) => {
    result[item[key]] = item;
  });
  return result;
}
